<template>
  <div class="newstrend">
    <div class="newstrend_center">
      <!-- <h1>新闻动态</h1> -->
      <header>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item v-if="nowtitle!==null">{{nowtitle}}</el-breadcrumb-item>
        </el-breadcrumb>
      </header>
      <main>
        <p class="r_title">{{nowtitle}}</p>
        <div class="r_list">
          <div class="liss" v-for="item in nowlistdata" :key="item.id">
            <p class="li_left" @click="cutpage(item)">{{item.title}}</p>
            <p class="li_right">{{item.time}}</p>
          </div>
        </div>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="curpage"
          :page-sizes="[10, 20, 30, 40,50]"
          :page-size="pagesize"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </main>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 面包屑
      routerName: null,
      routerNames: null,
      // 右侧列表
      nowtitle: "新闻动态",
      nowlistdata: null,
      // 分页
      curpage: 1,
      pagesize: 10,
      total: null,
    };
  },
  created() {
    console.log(this.$route.meta.title, "路由");
    this.routerName = this.$route.meta.title;
    this.querylist();
  },
  methods: {
    // 获取列表数据
    querylist() {
      let aa = [
        {
          id: 1,
          title: "朝阳区教科院“教研战疫”系列报道之初中教研篇",
          time: "2022-08-04",
        },
        {
          id: 2,
          title: "朝阳区教科院“教研战疫”系列报道之特殊教育篇",
          time: "2022-08-04",
        },
        {
          id: 3,
          title: "朝阳区教科院“教研战疫”系列报道之统筹部署篇",
          time: "2022-08-04",
        },
        {
          id: 4,
          title: "创建研究型学校 助力学校高质量发展",
          time: "2022-08-04",
        },
        {
          id: 5,
          title: "优质资源协同赋能 专业支撑科学“双减”——教学研讨会顺利召开",
          time: "2022-08-04",
        },
        // {
        //   id: 6,
        //   title: "新闻动态标题新闻动态标题新闻动态标题新闻动态标题新闻动态标题",
        //   time: "2022-08-04",
        // },

        // {
        //   id: 7,
        //   title: "新闻动态标题新闻动态标题新闻动态标题新闻动态标题新闻动态标题",
        //   time: "2022-5-20",
        // },
        // {
        //   id: 8,
        //   title: "新闻动态标题新闻动态标题新闻动态标题新闻动态标题新闻动态标题",
        //   time: "2022-5-20",
        // },
        // {
        //   id: 9,
        //   title: "新闻动态标题新闻动态标题新闻动态标题新闻动态标题新闻动态标题",
        //   time: "2022-5-20",
        // },
        // {
        //   id: 10,
        //   title: "新闻动态标题新闻动态标题新闻动态标题新闻动态标题新闻动态标题",
        //   time: "2022-5-20",
        // },
      ];
      this.nowlistdata = aa;
      this.total = aa.length;
    },
    //
    cutpage(item) {
      console.log(item, "详情", this.nowtitle);
      this.$router.push({
        path: "/newinfo",
        query: {
          id: item.id,
          text: item.title,
        },
      });
    },
    //分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagesize = val;
      this.querylist();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.curpage = val;
      this.querylist();
    },
  },
};
</script>
<style lang="scss" scoped>
.newstrend {
  .newstrend_center {
    width: 1200px;
    margin: 0 auto;
    header {
      padding: 15px 0;
    }
    main {
      .r_title {
        padding-bottom: 20px;
        // width: 132px;
        // height: 29px;
        font-size: 22px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #141516;
        line-height: 26px;
      }
      .r_list {
        padding: 25px 15px;
        border-top: 2px solid #1977f9;
        min-height: 500px;
        .liss {
          margin-bottom: 27px;
          display: flex;
          justify-content: space-between;
          .li_left {
            width: 829px;
            cursor: pointer;
            // height: 28px;
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #141516;
            // line-height: 21px;;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .li_right {
            width: 101px;
            // height: 24px;
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #5d6066;
            // line-height: 21px;
          }
        }
      }
      .el-pagination {
        padding: 30px 0 0;
        border-top: 1px dotted #d2d2d2;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>